import { Link } from 'react-router-dom';

import { OperationToken, GetParticipantSortOrder } from 'api/organization';
import { GetParticipantsByOrgSortField } from 'api/participant';
import { AssignmentStatus, AssignmentType } from 'api/assignment';
import { BatteryStatus } from 'components/shared/BatteryStatus/BatteryStatus';
import {
	TableColumn,
	TableColumnOption,
} from '../../components/shared/DataTable/DataTable';
import { Kebab } from '../../components/shared/DataTable/columnComponents/Kebab';
import { ShowIfAuthorized } from '../../components/shared/ShowIfAuthorized';
import { ButtonSm } from '../../components/shared/designSystem';
import { icons } from '../../enums/icons';
import i18n from '../../i18n';
import { dateTimeFormatter } from '../../stringStrategy/dateFormatStringStrategy';
import { TParticipantDetails } from './participantSchema';

const hasCurrentAssignment = (
	status: AssignmentStatus | undefined
): boolean => {
	return (
		!!status &&
		status !== AssignmentStatus.Deleted &&
		status !== AssignmentStatus.Complete
	);
};

const columns = (
	dateFormat: string,
	showVisitTypes = false,
	columnOptions?: TableColumnOption<TParticipantDetails>[],
	addBatteryCallback?: (x: TParticipantDetails) => void
): TableColumn<TParticipantDetails>[] => {
	const tableColumns: TableColumn<TParticipantDetails>[] = [
		{
			propertyName: 'externalId',
			headerDisplay: i18n.t('research.participantModal.participantId'),
			sortable: true,
			minWidth: '100px',
			sortProperty: GetParticipantsByOrgSortField.ExternalId,
			rowColumnComponent: ({ row }) => {
				return (
					<Link to={`/participants/${row?.id}`}>
						{row?.externalId}
					</Link>
				);
			},
			sortDir: GetParticipantSortOrder.Asc,
		},
		{
			propertyName: 'birthYear',
			headerDisplay: i18n.t('web.participant.birthYear'),
			sortable: true,
			sortProperty: GetParticipantsByOrgSortField.BirthYear,
			minWidth: '100px',
		},
		{
			propertyName: 'lastCompletedBattery',
			headerDisplay: i18n.t('web.patients.lastCompletedBattery'),
			sortable: false,
			minWidth: '324px',
			rowColumnComponent: ({ row }) => {
				const lastCompletedBattery = row?.lastCompletedBattery ?? '';
				const formattedDate = dateTimeFormatter(
					lastCompletedBattery,
					dateFormat,
					navigator.language
				);
				return !lastCompletedBattery ? (
					<>{i18n.t(`web.shared.none`)}</>
				) : (
					<>{formattedDate}</>
				);
			},
		},
		{
			propertyName: 'batteryStatus',
			headerDisplay: i18n.t('web.patients.batteryStatus'),
			sortable: false,
			minWidth: '192px',
			rowColumnComponent: ({ row }) => {
				return !hasCurrentAssignment(row?.assignmentInfo?.status) ? (
					<ShowIfAuthorized
						operations={[OperationToken.AssignBattery]}>
						<ButtonSm
							onClick={() => {
								return (
									addBatteryCallback &&
									addBatteryCallback({
										...(row as TParticipantDetails),
										newPatient: false,
									})
								);
							}}
							width='160px'
							text={i18n.t('web.patients.forms.addBattery')}
							IconLeft={icons.AddOutlined}
						/>
					</ShowIfAuthorized>
				) : (
					<BatteryStatus
						assignmentId={row?.assignmentInfo?.id ?? ''}
						assignmentStatus={row?.assignmentInfo?.status}
						assignmentType={
							row?.assignmentInfo?.type as AssignmentType
						}
						batteryDisplayKey={
							row?.assignmentInfo?.batteryDisplayKey
						}
					/>
				);
			},
		},
		{
			propertyName: '',
			sortable: false,
			width: '88px',
			minWidth: '88px',
			rowColumnComponent: ({ row, column }) => {
				return hasCurrentAssignment(row?.assignmentInfo?.status) ? (
					<Kebab row={row} column={column} />
				) : (
					<></>
				);
			},
			options: columnOptions,
		},
	];
	// Add the "Visit" column conditionally (after YOB column)
	if (showVisitTypes === true) {
		const lastCompletedBatteryColumnIndex = tableColumns.findIndex(
			(column) => column?.propertyName === 'lastCompletedBattery'
		);
		const visitTypeColumn = {
			propertyName: 'visitType',
			headerDisplay: i18n.t('web.visitTypes.visit'),
			sortable: false,
			minWidth: '100px',
		};
		tableColumns.splice(
			lastCompletedBatteryColumnIndex,
			0,
			visitTypeColumn
		);
	}
	return tableColumns;
};

const participantTableSchema = { columns };
export { participantTableSchema };
