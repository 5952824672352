import { SortDir, Scalars } from '../../../../generated/graphql';
export const DEFAULT_PAGE_SIZE = 10;
export type SortProps<T> = {
	dir: SortDir;
	prop: T;
};

export type SearchProps<T> = {
	value: string;
	prop: T;
};

export type GetRequestParamsProps<SortEnum, SearchEnum> = {
	page?: number;
	sort?: SortProps<SortEnum>;
	search?: SearchProps<SearchEnum>;
	pageSize?: number;
};

type BasePager<SortEnum, SearchEnum> = {
	limit: Scalars['Int'];
	offset: Scalars['Int'];
	sort?: SortProps<SortEnum>;
	search?: SearchProps<SearchEnum>;
};

export const getRequestParams = <
	T extends BasePager<SortEnum, SearchEnum>,
	SortEnum,
	SearchEnum
>({
	page,
	sort,
	search,
	pageSize = DEFAULT_PAGE_SIZE,
}: GetRequestParamsProps<SortEnum, SearchEnum>): T => {
	const params: Partial<T> = {};
	if (page) {
		params['limit'] = pageSize;
		params['offset'] = (page - 1) * pageSize;
	}
	if (sort?.prop) {
		params['sort'] = sort;
	}
	if (search?.value) {
		params['search'] = search;
	}
	return params as T;
};
