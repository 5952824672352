import styled, { css } from 'styled-components';
import { EnumItem } from '../../../../enums/enumeration';
import { SortDir } from '../../../../generated/graphql';
import { OperationToken } from '@lh/core-backend-client';
import { EmptyTableContent } from '../emptyTableContent/EmptyTableContent';
import { Row } from '../Row';
import { PaginatedHeader } from './PaginatedHeader';

type HeaderColumnComponentProps<T> = {
	column: TableColumn<T>;
};

export type RowColumnProps<T> = {
	column: TableColumn<T>;
	value: T[keyof T];
	row?: T;
};

export type TableColumnOption<T> = {
	key: string;
	value: string;
	callback: (row: T) => void;
	operations?: OperationToken[];
};

export type TableColumn<T> = {
	propertyName: string;
	// TODO: Fix the issue with this and DataTables.stories.tsx
	formatProperty?: (format: RowColumnProps<T>) => T[keyof T];
	rowColumnComponent?: (format: RowColumnProps<T>) => JSX.Element | null;
	width?: string;
	minWidth?: string;
	headerDisplay?: string;
	headerColumnComponent?: (
		format: HeaderColumnComponentProps<T>
	) => JSX.Element;
	sortable?: boolean;
	sortDir?: SortDir;
	sortProperty?: string;
	options?: TableColumnOption<T>[];
};

export type PaginatedDataTableProps<T> = {
	columns: TableColumn<T>[];
	tableData: T[];
	paginatedSort?: (dir: SortDir | undefined, prop: string) => void;
	deviantWidth?: string;
	fallbackText: string;
	longText?: string;
	noDataIcon?: EnumItem;
	isFilteringData?: boolean;
	notFoundTitle?: string;
	notFoundSubtitle?: string;
	loading: boolean;
	hasInitialData?: boolean;
	activeHeader?: string;
};

export const PaginatedDataTable = <T,>({
	columns,
	tableData = [],
	paginatedSort,
	fallbackText,
	longText,
	noDataIcon,
	deviantWidth,
	isFilteringData,
	notFoundTitle = '',
	notFoundSubtitle = '',
	hasInitialData,
	loading,
	activeHeader,
}: PaginatedDataTableProps<T>): JSX.Element => {
	const emptyTableData = tableData.length === 0;

	return (
		<StyledContainer emptyTableData={emptyTableData}>
			<PaginatedHeader
				columns={columns}
				onSortData={paginatedSort}
				deviantWidth={deviantWidth}
				activeHeader={activeHeader}
			/>
			{emptyTableData && !loading ? (
				<EmptyTableContent
					notFoundTitle={notFoundTitle}
					notFoundSubtitle={notFoundSubtitle}
					isFilteringData={isFilteringData}
					longText={longText}
					fallbackText={fallbackText}
					noDataIcon={noDataIcon}
					hasInitialData={hasInitialData}
				/>
			) : (
				tableData.map((data, idx) => (
					<Row
						columns={columns}
						dataRow={data}
						data-testid='data_table_row'
						key={idx}
						deviantWidth={deviantWidth}
					/>
				))
			)}
		</StyledContainer>
	);
};

type StyledContainerProps = {
	emptyTableData: boolean;
};
const StyledContainer = styled.div<StyledContainerProps>(
	({ theme: { color }, emptyTableData }) => css`
		border-bottom: ${!emptyTableData && `1px solid ${color.tableBorder}`};
		overflow-y: visible;
		overflow-x: auto;
	`
);
