import { createContext } from 'react';
import {
	Organization,
	PaginatedOrganization,
	UserOrganizationsRole,
} from '@lh/core-backend-client';
import { ValueListContainer } from 'store';

export type OrganizationContextProps = {
	setActiveOrganizationId: (orgId: string) => void;
	currentOrganization: Organization | null;
	currentOrganizationValueLists: ValueListContainer | null;
	currentOrganizationRole: UserOrganizationsRole | null;
	allUserOrganizations: PaginatedOrganization[] | null;
};

export const OrganizationSessionContext =
	createContext<OrganizationContextProps>({
		setActiveOrganizationId: () => undefined,
		allUserOrganizations: null,
		currentOrganization: null,
		currentOrganizationRole: null,
		currentOrganizationValueLists: null,
	});
