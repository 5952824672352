import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { UserStatus } from '../../generated/graphql';
import { AuthenticationLayout } from '../../components/authentication';
import { Layout } from '../../components/layout/Layout';
import { config } from '../../config';
import { Navigate, useLocation } from 'react-router-dom';
import { useZendeskRedirect } from 'hooks';

export const LoggedInCheck = (): JSX.Element => {
	const { currentUser } = useContext(UserContext);
	const location = useLocation();
	useZendeskRedirect();
	if (
		currentUser?.userStatus === UserStatus.Invited &&
		config.preferredUserProvider === 'auth0'
	) {
		return <AuthenticationLayout finishingSignup={true} />;
	}
	if (currentUser?.id && location.pathname) {
		// If you're already logged in, we should redirect to home page
		if (
			location.pathname.match('/auth/login') ||
			location.pathname.match('/auth/finish-signup')
		) {
			return <Navigate to={'/'} />;
		}
		if (location.pathname.match('/auth/reset-password')) {
			//If we match the above locations we allow the user to access
			//even if it's signed-in
			return <AuthenticationLayout />;
		}
	}

	return currentUser.id ? <Layout /> : <AuthenticationLayout />;
};
