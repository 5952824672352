// TODO: Swap to using core-backend-client https://linushealth.atlassian.net/browse/CA-3857
import { GetPaginatedUsersInput } from '.';

export {
	Configuration as OrganizationServiceConfiguration,
	FeatureType,
	GetUsersFilterField,
	GetUsersFilterOperator,
	GetUsersLogicalOperator,
	GetUsersSortField,
	GetUsersSortOrder,
	OperationToken,
	OrganizationType,
	RoleNames,
	UserOrganizationsRoleStatus,
	UserStatus,
	ValueListType,
	VisitTypeStatus,
} from '@lh/eng-platform-organization-service-rest-client';

export {
	DefaultApi as OrganizationService,
	SearchApi as OrganizationSearchService,
	GetPaginatedSubjectsSortField as GetParticipantsSortField,
	GetPaginatedSubjectsSortOrder as GetParticipantSortOrder,
	GetPaginatedSubjectsFields,
	GetPaginatedSubjectsFilterField as GetParticipantsByOrgFilterField,
	GetPaginatedSubjectsFilterOperator as GetParticipantsByOrgFilterOperator,
	GetPaginatedSubjectsLogicalOperator as GetParticipantsLogicalOperator,
} from '@lh/core-backend-client';

export type {
	Organization as IOrganization,
	DefaultApiGetUsersRequest as GetPaginatedUsersInput,
	DefaultApiGetRolesByOrgIdRequest as GetRolesByOrgIdInput,
	DefaultApiInviteUserRequest as InviteUserInput,
	DefaultApiUpdateUserRequest as IUpdateOrgUserInput,
	DefaultApiUpdateUserOrganizationsRoleStatusRequest as IUpdateOrgUserStatusInput,
	GetPaginatedSubjects200Response,
	SearchApiGetPaginatedSubjectsRequest,
	SearchSubjects,
	PaginatedAssignments as IPaginatedAssignments,
	PaginatedUsersV2 as IPaginatedUsers,
	User as IOrganizationUser,
	Role,
	ValueListItem,
	VisitType,
} from '@lh/core-backend-client';

export type GetUsersFilterOptions = {
	filterField: GetPaginatedUsersInput['filterField'];
	filterOperator: GetPaginatedUsersInput['filterOperator'];
	filterValue: GetPaginatedUsersInput['filterValue'];
	logicalOperator: GetPaginatedUsersInput['logicalOperator'];
};

export type GetUsersSortOptions = {
	sortField: GetPaginatedUsersInput['sortField'];
	sortOrder: GetPaginatedUsersInput['sortOrder'];
};
