import { useContext, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AboutPage } from '../../components/about/AboutPage';
import {
	ZendeskArticle,
	ZendeskArticleKeys,
} from '../../components/about/ZendeskArticle';
import { BatteryResults } from '../../components/batteryResults/BatteryResults';
import { ParticipantDetails, Participants } from '../../components/participant';
import { Report } from '../../components/report';
import { Team } from '../../components/team';
import { UserProfile } from '../../components/userProfile';
import { UserContext } from '../../context/UserContext';

import { AuthenticatedRoute } from './AuthenticatedRoute';
import { RoleBasedRoute } from './RoleBasedRoute';
import { loginLandingPage } from './loginLandingPage/loginLandingPage';

export const AuthenticatedRoutes = (): JSX.Element | null => {
	const { currentUser } = useContext(UserContext);

	const landingPage = useMemo(
		() => loginLandingPage(currentUser),
		[currentUser]
	);

	return (
		<Routes>
			<Route
				path='/organization'
				element={
					<RoleBasedRoute>
						<Team />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/participants/:participantId'
				element={
					<RoleBasedRoute>
						<ParticipantDetails />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/participants'
				element={
					<RoleBasedRoute>
						<Participants />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/profile'
				element={
					<AuthenticatedRoute>
						<UserProfile />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path='/results/:participantId/:reportId'
				element={
					<RoleBasedRoute>
						<Report />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/results'
				element={
					<RoleBasedRoute>
						<BatteryResults />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/about/instructions-for-use'
				element={
					<RoleBasedRoute>
						<ZendeskArticle
							article={ZendeskArticleKeys.researchIFU}
						/>
					</RoleBasedRoute>
				}></Route>
			<Route path='/about' element={<AboutPage />} />
			<Route
				path='*'
				element={
					<Navigate to={`${landingPage}${window.location.search}`} />
				}
			/>
		</Routes>
	);
};
