import { useCallback, useContext, useState } from 'react';

import { Form, Formik, FormikHelpers } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
	OperationToken,
	IOrganizationUser,
	Role,
	IUpdateOrgUserInput,
	UserOrganizationsRoleStatus,
	ValueListType,
	useActivateOrgUser,
	useDeactivateOrgUser,
	useGetUser,
	useInviteUserToOrg,
	useUpdateOrgUser,
} from 'api/organization';
import { sendEventData } from '../../../analytics/amplitude';
import { UserContext } from '../../../context/UserContext';
import { AnalyticsAction } from '../../../enums/analyticsAction';
import { MessageEnumItem, messageEnum } from '../../../enums/messageEnum';
import { userStatus } from '../../../enums/userStatus';
import { ERROR } from '../../../logging/linusLogger';
import { AuthProvider } from '../../../providers/AuthProvider';
import {
	EditUserModel,
	editUserSchema,
	getUserModel,
} from '../../../schemas/forms/editUserSchema';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { InfoMessage } from '../../shared/InfoMessage';
import { LinusModalDialog } from '../../shared/LinusModalDialog';
import { ShowIfAuthorized } from '../../shared/ShowIfAuthorized';
import { ButtonLg } from '../../shared/designSystem';
import { messages } from '../../shared/errorMessages';
import {
	ChangeStatusModal,
	PayloadType,
} from '../ConfirmationModals/ChangeStatusModal';
import { UserStatusComponent } from '../UserStatusComponent';
import { LoadingDots } from 'components/shared/LoadingDots';

export type EditUserFormProps = {
	roles: Role[] | undefined;
	onCancel: () => void;
	onFinish?: () => void;
	userId: string;
};

const TAG_NAME = 'EditUserForm';

const EditUserForm = ({
	roles = [],
	onCancel,
	userId,
}: EditUserFormProps): JSX.Element | null => {
	const [showModal, setShowModal] = useState('');
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();

	const { currentUser: loggedInUser } = useContext(UserContext);
	const { t, i18n } = useTranslation();

	const { data: currentUser, isFetching: loading } = useGetUser({
		userId: userId,
		organizationId: loggedInUser?.organizationId,
	});

	const { mutateAsync: updateUserMutation, isPending: isUpdateUserPending } =
		useUpdateOrgUser();

	const { mutateAsync: activateUser } = useActivateOrgUser();

	const { mutateAsync: deactivateUser } = useDeactivateOrgUser();

	const {
		mutateAsync: resendInvitation,
		isPending: isResendInvitationPending,
	} = useInviteUserToOrg();

	const hideConfirmationModal = useCallback(() => {
		setShowModal('');
	}, []);

	const updateUser = async (values: EditUserModel) => {
		try {
			const organizationId = loggedInUser?.organizationId;
			const updateUserPayload: IUpdateOrgUserInput = {
				userId: values.id,
				userUpdate: {
					clientId: AuthProvider.getClientId(),
					firstName: values.firstName,
					lastName: values.lastName,
					suffix: values?.suffixIds?.split(', ').filter(Boolean),
					phoneNumber: values.phoneNumber,
					role: { roleId: values.role, organizationId },
				},
				organizationId: organizationId,
			};
			await updateUserMutation(updateUserPayload);
			return true;
		} catch (error) {
			setServerSideMessage(
				messageEnum.error(messages.mutationPayloadError)
			);
			return false;
		}
	};

	const onSubmit = async (
		values: EditUserModel,
		{ setSubmitting }: FormikHelpers<EditUserModel>
	) => {
		const changeStatus = [
			{ activate: values.activate },
			{ deactivateUser: values.deactivate },
			{
				resendInvitation:
					values.invitedUserActions === 'resendInvitation',
			},
			{
				cancelInvitation:
					values.invitedUserActions === 'cancelInvitation',
			},
		] // Array of objects for each of the different status.
			.filter((x) => Object.values(x)[0]) // Filter through the array of objects to pull objects with true values
			.map((x) => Object.keys(x)[0])[0]; // Pull out key from filter, will either come out as the key or as undefined.
		if (changeStatus) {
			// not sure how this is working just yet
			setShowModal(changeStatus);
			updateUser(values);
		} else {
			const success = await updateUser(values);
			if (!success) {
				setSubmitting(false);
				return;
			}
			setShowSuccessModal(true);
		}
		sendEventData({
			eventType: AnalyticsAction.UpdatedUser,
			eventProperties: {
				changeStatus,
				userId: values.id,
				userRole: values.role,
			},
		});
		setSubmitting(false);
	};

	const doOnStatusChangeFailed = useCallback(() => {
		hideConfirmationModal();
		setServerSideMessage(messageEnum.error(messages.mutationPayloadError));
	}, [hideConfirmationModal]);

	const onConfirmStatusChange = async (
		values: EditUserModel,
		payload: PayloadType
	) => {
		try {
			switch (payload.status) {
				case userStatus.Deactivated.value:
					await deactivateUser({
						userId,
						organizationId: loggedInUser.organizationId,
						status: UserOrganizationsRoleStatus.Deactivated,
					});
					break;
				case userStatus.Active.value:
					await activateUser({
						userId,
						organizationId: loggedInUser.organizationId,
						status: UserOrganizationsRoleStatus.Active,
					});
					break;
				case userStatus.Invited.value:
					await resendInvitation({
						organizationId: loggedInUser.organizationId,
						userInvite: {
							clientId: AuthProvider.getClientId(),
							email: values?.email,
							firstName: values?.firstName,
							lastName: values?.lastName,
							roleId: values?.roles?.at(0)?.roleId ?? '',
							organizationId: loggedInUser?.organizationId,
						},
					});
					break;
				default:
					throw new Error(
						`Invalid status type; should be one of - ${userStatus?.toValues()}`
					);
			}
		} catch (error) {
			ERROR((error as { message: string })?.message);
			doOnStatusChangeFailed();
		}
	};

	if (loading) {
		return <LoadingDots />;
	}

	return (
		<>
			<Formik
				initialValues={getUserModel(currentUser as IOrganizationUser)}
				validationSchema={editUserSchema()}
				onSubmit={onSubmit}>
				{({ isSubmitting, values }) => {
					return showModal ? (
						<ChangeStatusModal
							statusAction={showModal}
							firstname={values.firstName}
							lastname={values.lastName}
							onCancelModal={() => {
								hideConfirmationModal();
								onCancel();
							}}
							onSubmit={onConfirmStatusChange}
						/>
					) : (
						<StyledForm>
							<StyledRow>
								<LinusInput
									width='257px'
									name='firstName'
									label={t`web.team.sharedModal.firstNameLabel`}
								/>
								<LinusInput
									width='257px'
									name='lastName'
									label={t`web.team.sharedModal.lastNameLabel`}
								/>
								<LinusInput
									width='257px'
									name='suffixIds'
									label={t`web.team.sharedModal.suffixLabel`}
									type='multi'
									preferDisplayLength='long'
									dropdownOptions={
										loggedInUser?.organizationValueLists?.[
											ValueListType.Suffix
										]?.toOptions?.() || []
									}
								/>
							</StyledRow>
							<StyledRow>
								<LinusInput
									name='email'
									label={t`web.team.sharedModal.emailAddressLabel`}
									type='email'
									disabled
									width='402px'
								/>
								<LinusInput
									name='phoneNumber'
									label={t`web.team.sharedModal.phoneNumberLabel`}
									width='402px'
								/>
							</StyledRow>
							<StyledRow>
								<LinusInput
									name='role'
									type='select'
									label={t`web.team.sharedModal.userRoleLabel`}
									width='100%'
									dropdownOptions={(roles || []).map((r) => ({
										display: i18n.t(r.displayKey),
										value: r.id,
									}))}
									value={values.role}
								/>
							</StyledRow>
							<StyledStatusRow>
								<UserStatusComponent
									name='status'
									label={t`web.team.editUserModal.statusLabel`}
								/>
							</StyledStatusRow>
							<InfoMessage
								messageEnum={serverSideMessage}
								showIf={!!serverSideMessage}
							/>
							<StyledButtonRow>
								<StyledButtonWrapper>
									<ButtonLg
										onClick={onCancel}
										text={t`web.team.sharedModal.cancelButton`}
										width='300px'
									/>
								</StyledButtonWrapper>
								<ShowIfAuthorized
									operations={[OperationToken.EditUser]}>
									<ButtonLg
										primary
										disabled={
											isSubmitting || isUpdateUserPending
										}
										loading={
											isUpdateUserPending ||
											isResendInvitationPending
										}
										text={t`web.team.editUserModal.saveChangesButton`}
										type='submit'
										width='300px'
									/>
								</ShowIfAuthorized>
							</StyledButtonRow>
						</StyledForm>
					);
				}}
			</Formik>
			{showSuccessModal && (
				<LinusModalDialog
					onClose={() => {
						setShowSuccessModal(false);
						onCancel();
					}}
					title={t`web.team.changesSavedModal.title`}
					declineButtonCallback={() => {
						setShowSuccessModal(false);
						onCancel();
					}}
					declineButtonText={t`web.team.sharedModal.closeButton`}
					width={'500px'}>
					<Trans
						i18nKey={'web.team.changesSavedModal.hasBeenSaved'}
						values={{
							firstName: currentUser?.firstName,
							lastName: currentUser?.lastName,
						}}
						components={[
							<strong key={'strong_text_saved_modal'} />,
						]}
					/>
				</LinusModalDialog>
			)}
		</>
	);
};

const StyledForm = styled(Form)`
	width: auto;
	margin: 0;
	padding: 0;
`;

const StyledButtonRow = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	justify-content: center;
	padding: ${spacing.md};
	`
);
const StyledButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
	margin: 0 ${spacing.xl} 0 0;
`
);
const StyledRow = styled.div`
	width: 836px;
	display: flex;
	justify-content: space-between;
`;
const StyledStatusRow = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	justify-content: space-between;
	margin: ${spacing.sm} 0;
`
);

export { EditUserForm };
