import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
	GetBatteryResultsByOrganizationSortOptions,
	GetResearchAssignmentsSortField,
	GetResearchAssignmentsSortOrder,
} from 'api/battery';
import { FeatureType } from 'api/organization';
import { sendEventData } from 'analytics/amplitude';
import { FormElementError } from 'components/shared/Forms/Components/FormElementError';
import { icons } from 'enums/icons';
import {
	AnalyticsAction,
	ResearchBatteryResultsFragment,
	SortDir,
} from 'generated/graphql';
import { useGetBatteryResultsTableData } from './useGetBatteryResultsTableData';
import { LinusPaginatedDataTable } from '../shared/DataTable/PaginatedDataTable';
import { Header } from '../shared/Header';
import { useActiveTableHeader } from '../shared/hooks';
import { UserContext } from '../../context/UserContext';
import { useGetOrgEntitlements } from '../../hooks/useGetOrgEntitlements';
import {
	batteryResultsSchema,
	TOTAL_RESULTS_PER_PAGE,
} from '../../schemas/table/batteryResultsSchema';
import i18n from './../../i18n';

type BatteryResultsStrings = {
	filterPlaceHolder: string;
	notFoundTitleEntity: string;
	notFoundSubtitleEntity: string;
	querySizeLimitExceededTitle: string;
	querySizeLimitExceededSubtitle: string;
};

const batteryResultsStrings: BatteryResultsStrings = {
	filterPlaceHolder: 'research.participantsTable.searchLabel',
	notFoundTitleEntity: 'web.results.participantResult',
	notFoundSubtitleEntity: 'web.participant.value',
	querySizeLimitExceededTitle: 'research.somethingWentWrong',
	querySizeLimitExceededSubtitle:
		'research.participantsTable.querySizeLimitExceededSubtitle',
};

const defaultSortOptions: GetBatteryResultsByOrganizationSortOptions = {
	sortField: [GetResearchAssignmentsSortField.EndTime],
	sortOrder: [GetResearchAssignmentsSortOrder.Desc],
};

const BatteryResults = (): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { hasFeatureEntitlement } = useGetOrgEntitlements();

	useEffect(() => {
		sendEventData({ eventType: AnalyticsAction.ViewedResults });
	}, []);

	const { currentUser } = useContext(UserContext);
	const { activeHeader, setActiveHeader } = useActiveTableHeader();

	const [page, setPage] = useState(1);
	const [sort, setSort] = useState<
		GetBatteryResultsByOrganizationSortOptions | undefined | null
	>(defaultSortOptions);
	const [search, setSearch] = useState<string | null>(null);

	const {
		totalCount,
		data: tableData,
		error,
		loading,
	} = useGetBatteryResultsTableData({
		organizationId: currentUser?.organizationId,
		page: page - 1,
		pageSize: TOTAL_RESULTS_PER_PAGE,
		sort: sort,
		searchValue: search,
	});

	const dateFormat = currentUser.organizationDateFormat || 'MM/dd/yyyy';

	const noResultsTitle = useMemo(() => {
		return i18n.t('web.shared.search.noMatchFound', {
			entity: t(batteryResultsStrings.notFoundTitleEntity).toLowerCase(),
		});
	}, [t]);

	const noResultsSubtitle = useMemo(() => {
		return i18n.t('web.results.notFoundSubtitle', {
			entity: t(
				batteryResultsStrings.notFoundSubtitleEntity
			).toLowerCase(),
		});
	}, [t]);

	const onSort = useCallback(
		(dir: SortDir | undefined, prop: string) => {
			if (!dir) {
				setSort(null);
			} else {
				setActiveHeader(prop);
				setSort({
					sortField: [prop as GetResearchAssignmentsSortField],
					sortOrder: [dir as GetResearchAssignmentsSortOrder],
				});
			}
		},
		[setSort, setActiveHeader]
	);

	const hasVisitTypesEntitlement = useMemo(
		() => hasFeatureEntitlement(FeatureType.VisitTypes),
		[hasFeatureEntitlement]
	);
	const tableColumns = useMemo(
		() =>
			batteryResultsSchema.columns(
				theme,
				dateFormat,
				hasVisitTypesEntitlement
			),
		[dateFormat, hasVisitTypesEntitlement, theme]
	);

	return (
		<>
			<Header />
			{error ? (
				<FormElementError>{(error as Error)?.message}</FormElementError>
			) : null}
			<LinusPaginatedDataTable
				activeHeader={activeHeader}
				title={t`web.results.title`}
				columns={tableColumns}
				tableData={tableData}
				rowsPerPage={TOTAL_RESULTS_PER_PAGE}
				count={TOTAL_RESULTS_PER_PAGE}
				longText={t('web.results.emptyTablePlaceholder', {
					entity: t('web.participant.value').toLowerCase(),
				})}
				noDataIcon={icons.EmptyClipboardSearch}
				searchBarPlaceholder={t(
					batteryResultsStrings.filterPlaceHolder
				)}
				notFoundTitle={noResultsTitle}
				notFoundSubtitle={noResultsSubtitle}
				total={totalCount}
				currentPage={page}
				setCurrentPage={(pageNumber: number) => setPage(pageNumber)}
				loading={loading}
				hasInitialData={false}
				onSort={onSort}
				onFilter={setSearch}
			/>
		</>
	);
};

export { BatteryResults };
export type { ResearchBatteryResultsFragment };
