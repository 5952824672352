import { useContext, ReactNode } from 'react';
import { UserContext } from './../../context/UserContext';
import { hasOperation } from '../../hasOperation';
import { OperationToken } from '@lh/core-backend-client';

type ShowIfAuthorizedProps = {
	operations: OperationToken[];
	children: ReactNode;
	acceptEmpty?: boolean;
};

export const ShowIfAuthorized = ({
	operations,
	children,
	acceptEmpty,
}: ShowIfAuthorizedProps): JSX.Element | null => {
	const { currentUser } = useContext(UserContext);
	return acceptEmpty || hasOperation(currentUser, operations) ? (
		<>{children}</>
	) : null;
};
