import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';
import { ActivateUserEvents } from 'components/team/events';
import { DEBUG, ERROR } from 'logging/linusLogger';
import {
	IUpdateOrgUserStatusInput,
	UserOrganizationsRoleStatus,
} from './types';

export async function activateOrgUser(
	input: IUpdateOrgUserStatusInput
): Promise<void> {
	if (!input?.status || !input?.userId || !input.organizationId) {
		throw new Error('activateOrgUser: invalid API request params');
	}
	const service = await getOrganizationService();
	await service.updateUserOrganizationsRoleStatus({
		...input,
		status: UserOrganizationsRoleStatus.Active,
	});
}

export function useActivateOrgUser() {
	const queryClient = useQueryClient();
	return useMutation({
		meta: {
			errorMessage: 'Error activating user',
		},
		mutationFn: activateOrgUser,
		onMutate: () => {
			DEBUG(ActivateUserEvents.INIT);
		},
		onSuccess: () => {
			DEBUG(ActivateUserEvents.SUCCESS);
			queryClient.invalidateQueries({ queryKey: [QueryKey.Users] });
		},
		onError: (error) => {
			ERROR(ActivateUserEvents.FAILURE, error);
		},
	});
}
