import styled from 'styled-components';
import { EnumItem } from '../../../enums/enumeration';
import { OperationToken } from '@lh/core-backend-client';
import { ButtonSm } from '../designSystem/ButtonSm';
import { Icon } from '../designSystem/Icon';
import { H4 } from '../designSystem/TextComponents';
import { ShowIfAuthorized } from '../ShowIfAuthorized';

interface HeaderProps {
	title: string;
	titleIcon?: EnumItem;
	buttonText?: string;
	dataId?: string;
	onHeaderButtonClick?: (event: React.MouseEvent) => void;
	operations?: OperationToken[];
	buttonIcon?: EnumItem;
}

const DataTableHeader = ({
	title,
	titleIcon,
	buttonText,
	dataId,
	onHeaderButtonClick,
	buttonIcon,
	operations = [],
}: HeaderProps): JSX.Element => {
	return (
		<StyledHeaderContainer>
			<StyledTitle id={title.replace(/\s/g, '-').toLowerCase()}>
				{titleIcon && (
					<StyledIcon>
						<Icon
							icon={titleIcon}
							title={title}
							iconWidth={40}
							iconHeight={40}
						/>
					</StyledIcon>
				)}
				{title}
			</StyledTitle>
			<StyledHeaderContent>
				{onHeaderButtonClick && (
					<ShowIfAuthorized
						operations={operations}
						acceptEmpty={!operations || !operations.length}>
						<ButtonSm
							onClick={onHeaderButtonClick}
							primary={true}
							text={buttonText}
							dataId={dataId}
							dataTestId={dataId}
							IconLeft={buttonIcon}
						/>
					</ShowIfAuthorized>
				)}
			</StyledHeaderContent>
		</StyledHeaderContainer>
	);
};

export { DataTableHeader };

const StyledHeaderContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: ${spacing.md} 0 ${spacing.md} ${spacing.md};
	`
);
// TODO: trying to use styled(Icon) does not honor
// the styling here.
const StyledIcon = styled.div(
	({ theme: { spacing } }) => `
	margin-right: ${spacing.sm};
	`
);

const StyledTitle = styled(H4)``;
const StyledHeaderContent = styled.div`
	display: flex;
	justify-content: space-around;
	align-content: center;
`;
