import { useState } from 'react';
import {
	keepPreviousData,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';

import { QueryKey } from '../query';

import { getSearchService } from 'api/getSearchService';
import { isNonEmptyString } from 'utils/stringUtils';

import {
	GetPaginatedSubjects200Response,
	GetParticipantsSortField,
	GetParticipantSortOrder,
	GetParticipantsByOrgFilterField,
	GetParticipantsByOrgFilterOperator,
	GetParticipantsLogicalOperator,
} from '../organization';
import { AssignmentStatus, AssignmentType } from 'api/assignment';
import { DEFAULT_PAGE_SIZE } from 'components/shared/DataTable/PaginatedDataTable/pageHelpers';
import { useOrganizationStore } from 'store';
import { TParticipantDetails } from 'app/src/schemas/table/participantSchema';

type TFetchParticipants = {
	organizationId: string | null;
	signal: AbortSignal;
	requestOptions: PatientsParams;
};
export async function fetchParticipants({
	organizationId,
	requestOptions,
	signal,
}: TFetchParticipants): Promise<GetPaginatedSubjects200Response> {
	const {
		sortField,
		sortOrder,
		filterField,
		filterOperator,
		filterValue,
		logicalOperator,
		page,
	} = requestOptions;
	const searchService = await getSearchService();
	const { data } = await searchService.getPaginatedSubjects(
		{
			organizationId: organizationId ?? '',
			pageSize: DEFAULT_PAGE_SIZE,
			page,
			sortField,
			sortOrder,
			filterField,
			filterOperator,
			filterValue,
			logicalOperator,
		},
		{ signal }
	);

	return data;
}

export type PatientsParams = {
	sortField: GetParticipantsSortField[];
	sortOrder: GetParticipantSortOrder[];
	page: number;
	filterValue?: string[];
	filterField?: GetParticipantsByOrgFilterField[];
	filterOperator?: GetParticipantsByOrgFilterOperator[];
	logicalOperator?: GetParticipantsLogicalOperator[];
};
export function useGetPaginatedParticipantsQuery(
	requestOptions: PatientsParams
) {
	const organizationId = useOrganizationStore(
		(state) => state.currentOrganizationId
	);
	const client = useQueryClient();
	const [isRefreshing, setIsRefreshing] = useState(false);

	const query = useQuery({
		enabled: isNonEmptyString(organizationId),
		meta: {
			errorMessage: `Error fetching participants for org ${organizationId}`,
		},
		queryKey: [
			QueryKey.Participants,
			organizationId,
			requestOptions?.page,
			requestOptions?.sortOrder,
			requestOptions?.sortField,
			requestOptions?.filterValue,
			requestOptions?.filterOperator,
		],
		queryFn: ({ signal }) =>
			fetchParticipants({
				organizationId,
				requestOptions,
				signal,
			}),
		placeholderData: keepPreviousData,
		staleTime: Infinity,
		select: (data) => {
			return {
				data: (data?.results?.flat()?.filter(Boolean) ?? []).map(
					(x) => {
						return {
							id: x?.subjectId || '',
							firstName: x.firstName || '',
							lastName: x?.lastName || '',
							externalId: x?.externalId || '',
							birthYear: x?.birthYear || '',
							lastCompletedBattery: x?.endTime || '',
							assignmentInfo: {
								id: x?.assignmentId || '',
								status:
									x?.assignmentStatus ||
									('' as AssignmentStatus),
								type:
									x.assignmentType || ('' as AssignmentType),
								batteryDisplayKey: x.batteryDisplayKey || '',
							},
							contactEmail: x.contactEmail || '',
							newPatient: false,
							visitType: x?.visitType || '',
						};
					}
				) as TParticipantDetails[],
				totalCount: data?.totalCount,
			};
		},
	});

	async function refresh() {
		setIsRefreshing(true);
		await client.invalidateQueries({
			queryKey: [
				QueryKey.Participants,
				organizationId,
				requestOptions?.page,
				requestOptions?.sortOrder,
				requestOptions?.sortField,
				requestOptions?.filterValue,
				requestOptions?.filterOperator,
			],
		});
		setIsRefreshing(false);
	}
	return {
		...query,
		data: query?.data?.data || [],
		totalCount: query.data?.totalCount,
		isRefreshing,
		refresh,
	};
}
