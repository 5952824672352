import { OrganizationStatus } from 'generated/graphql';
import { useMemo } from 'react';
import { useOrganizationStore } from 'store';
import { isEmpty } from 'lodash';
import {
	OrganizationType,
	PaginatedOrganization,
} from '@lh/core-backend-client';

export type Organization = {
	id: string;
	name: string;
	isDefault?: boolean;
	status: OrganizationStatus;
};

export function sortOrganizationsByName(a: Organization, b: Organization) {
	if (a.name < b.name) {
		return 1;
	}
	if (a.name > b.name) {
		return -1;
	}

	return 0;
}

export function compareOrganizations(a: Organization, b: Organization) {
	if (a.isDefault) {
		return -1;
	}
	if (b.isDefault) {
		return 1;
	}
	if (a.status === OrganizationStatus.Live) {
		return -1;
	}
	if (b.status === OrganizationStatus.Live) {
		return 1;
	}
	return a.name.localeCompare(b.name);
}

export function sortOrganizations(orgs: Organization[]) {
	return orgs.sort(sortOrganizationsByName).sort(compareOrganizations);
}

export function useSwitchOrganization() {
	// TODO: Eventually hide this behind the OrgProvider
	const {
		allUserOrganizations,
		setCurrentOrganizationId,
		setPrimaryOrganizationId,
		primaryOrganizationId,
	} = useOrganizationStore();

	const organizations = useMemo(() => {
		if (isEmpty<PaginatedOrganization[]>(allUserOrganizations)) {
			return [];
		}

		return sortOrganizations(
			allUserOrganizations
				.filter(
					(organization) =>
						organization.type === OrganizationType.Research
				)
				.map((organization) => {
					const isDefaultOrganization =
						organization.id === primaryOrganizationId;

					return {
						id: organization.id,
						name: organization.name,
						isDefault: isDefaultOrganization,
						status: organization.status,
					} as Organization;
				})
		);
	}, [allUserOrganizations, primaryOrganizationId]);

	return {
		switchOrganization: setCurrentOrganizationId,
		setPrimaryOrganizationId,
		organizations,
	};
}
