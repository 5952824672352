import {
	GetParticipantsByOrgFilterField,
	GetParticipantsByOrgFilterOperator,
	GetParticipantsLogicalOperator,
	GetParticipantSortOrder,
	GetParticipantsSortField,
} from 'api/organization';
import { Sort } from './Participants';

export function getParams(search: string, sort: Sort | null, page: number) {
	let filterField: GetParticipantsByOrgFilterField[] = [];
	let filterOperator: GetParticipantsByOrgFilterOperator[] = [];
	let filterValue: string[] = [];
	let logicalOperator: GetParticipantsLogicalOperator[] = [];
	let sortField = [GetParticipantsSortField.CreatedAt];
	let sortOrder = [GetParticipantSortOrder.Desc];

	if (search.length) {
		filterField = [GetParticipantsByOrgFilterField.ExternalId];
		filterOperator = [GetParticipantsByOrgFilterOperator.Ilike];
		filterValue = [search];
		logicalOperator = [GetParticipantsLogicalOperator.Or];
	}

	if (sort) {
		sortField = sort.field;
		sortOrder = sort.order;
	}

	return {
		sortField,
		sortOrder,
		filterField,
		filterOperator,
		filterValue,
		logicalOperator,
		page: page - 1,
	};
}
