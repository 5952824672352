import {
	OrganizationSearchService,
	OrganizationServiceConfiguration,
} from 'api/organization';

import { config } from '../config';
import { useAuthStore } from 'store';

// TODO: Swap to using core-backend-client https://linushealth.atlassian.net/browse/CA-3857
export async function getSearchService() {
	try {
		const bearerToken = await useAuthStore?.getState()?.getBearerToken();
		return new OrganizationSearchService(
			new OrganizationServiceConfiguration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${bearerToken}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized');
	}
}
