import * as yup from 'yup';

import {
	IOrganizationUser,
	UserOrganizationsRoleStatus,
	UserStatus,
	ValueListItem,
} from 'api/organization';
import { phoneRegExp } from '../../components/shared/Forms/utils/phoneNumberFormat';

import i18n from '../../i18n';
import { maxCharacterLimits } from '../../utils/stringUtils';
import { determineUserStatus } from '../table/teamSchema';

export type EditUserModel = Omit<IOrganizationUser, 'role' | 'organization'> & {
	role: string;
	roleName?: string;
	activate: boolean;
	deactivate: boolean;
	phoneNumber?: string;
	suffixIds?: string;
	invitedUserActions?: string;
};

export const getUserModel = (
	user?: Omit<IOrganizationUser, 'organization'>
): EditUserModel => {
	const userGlobalStatus = user?.status ?? UserStatus.Deactivated;
	const userOrgStatus =
		user?.roles?.at(0)?.status ?? UserOrganizationsRoleStatus.Deactivated;
	const userStatus = determineUserStatus(userGlobalStatus, userOrgStatus);
	return {
		...user,
		status: userStatus,
		role: user?.roles?.at(0)?.roleId,
		roleName: user?.roles?.at(0)?.roleName,
		activate: false,
		deactivate: false,
		phoneNumber: user?.phoneNumber || '',
		suffixIds: user?.suffix
			?.map((v: ValueListItem): string => v.id)
			.join(', '),
	} as EditUserModel;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const editUserSchema = () => {
	return yup.object().shape(
		{
			firstName: yup
				.string()
				.required(i18n.t('web.shared.forms.schemas.required'))
				.max(
					maxCharacterLimits.firstname,
					i18n.t('web.shared.forms.schemas.maxCharLimit')
				),
			lastName: yup
				.string()
				.max(
					maxCharacterLimits.lastname,
					i18n.t('web.shared.forms.schemas.maxCharLimit')
				)
				.required(i18n.t('web.shared.forms.schemas.required')),
			email: yup
				.string()
				.email(i18n.t('web.authErrors.invalidEmail'))
				.required(i18n.t('web.shared.forms.schemas.required')),
			role: yup
				.string()
				.required(i18n.t('web.shared.forms.schemas.required')),
			phoneNumber: yup
				.string()
				.max(20, i18n.t('web.shared.forms.schemas.invalidPhone'))
				.nullable()
				.notRequired()
				.when('phoneNumber', {
					is: (value: string) => value?.length,
					then: yup
						.string()
						.matches(
							phoneRegExp,
							i18n.t('web.shared.forms.schemas.invalidPhone')
						),
				}),
		},
		[
			// Add cyclic dependencies when making 'requires' to itself
			['phoneNumber', 'phoneNumber'],
		]
	);
};
