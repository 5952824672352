import { useQuery } from '@tanstack/react-query';
import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';
import { isNonEmptyString } from 'utils/stringUtils';
import { IOrganization } from './types';

export async function fetchOrganizationById(
	organizationId: string | null
): Promise<IOrganization> {
	if (!isNonEmptyString(organizationId)) {
		throw new Error(
			'useGetOrganization: organization ID invalid or missing'
		);
	}
	const service = await getOrganizationService();
	const { data } = await service.getOrganization({ organizationId });
	return data;
}

export function useGetOrganization(organizationId: string | null) {
	const isValidOrgId = isNonEmptyString(organizationId);
	return useQuery({
		enabled: isValidOrgId,
		meta: {
			errorMessage: `Error fetching organization with ID ${organizationId}`,
		},
		queryKey: [QueryKey.Organization, organizationId],
		queryFn: () => fetchOrganizationById(organizationId),
		staleTime: Infinity,
	});
}
