import {
	SearchApi as BatterySearchApi,
	//enums
	GetResearchAssignmentsFilterField,
	GetResearchAssignmentsFilterOperator,
	GetResearchAssignmentsSortField,
	GetResearchAssignmentsSortOrder,
	GetResearchAssignmentsLogicalOperator,
	PaginatedAssignmentsResearch,
	SearchApiGetResearchAssignmentsRequest,
	AssignmentMetrics,
} from '@lh/core-backend-client';

export {
	AssessmentType,
	AudienceType,
	BatteryResultState,
	BatteryResultStatus,
	DefaultApi as BatteryService,
	Configuration as BatteryServiceConfiguration,
	BatteryType,
	GetBatteryResultsByOrganizationFilterField,
	GetBatteryResultsByOrganizationFilterOperator,
	GetBatteryResultsByOrganizationLogicalOperator,
	GetBatteryResultsByOrganizationSortField,
	GetBatteryResultsByOrganizationSortOrder,
	GetBatteryResultsByParticipantFilterField,
	GetBatteryResultsByParticipantFilterOperator,
	GetBatteryResultsByParticipantSortField,
	GetBatteryResultsByParticipantSortOrder,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';

export type {
	Battery,
	BatteryResult,
	DeepAssessment,
	DeepAssessmentResult,
	DeepBattery,
	DeepSegmentResult,
	DefaultApiGetBatteryResultsByOrganizationRequest as GetBatteryResultsByOrganizationRequest,
	DefaultApiGetSegmentResultArtifactRequest as GetSegmentResultArtifactRequest,
	MetricItem,
	PaginatedBatteryResults,
	DeepBatteryResult,
} from '@lh/eng-platform-battery-service-rest-client';

export {
	BatterySearchApi,
	//enums
	GetResearchAssignmentsFilterField,
	GetResearchAssignmentsFilterOperator,
	GetResearchAssignmentsSortField,
	GetResearchAssignmentsSortOrder,
	GetResearchAssignmentsLogicalOperator,
};

export type {
	AssignmentMetrics,
	PaginatedAssignmentsResearch,
	SearchApiGetResearchAssignmentsRequest,
};

export type GetBatteryResultsByOrganizationFilterOptions = {
	filterField: SearchApiGetResearchAssignmentsRequest['filterField'];
	filterOperator: SearchApiGetResearchAssignmentsRequest['filterOperator'];
	filterValue: SearchApiGetResearchAssignmentsRequest['filterValue'];
	logicalOperator: SearchApiGetResearchAssignmentsRequest['logicalOperator'];
};

export type GetBatteryResultsByOrganizationSortOptions = {
	sortField: SearchApiGetResearchAssignmentsRequest['sortField'];
	sortOrder: SearchApiGetResearchAssignmentsRequest['sortOrder'];
};
