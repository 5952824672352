import {
	BatteryResultState,
	GetResearchAssignmentsFilterField,
	GetResearchAssignmentsFilterOperator,
	GetResearchAssignmentsLogicalOperator,
	PaginatedAssignmentsResearch,
	SearchApiGetResearchAssignmentsRequest,
} from 'api/battery';
import { isNonEmptyString, truncateString } from 'utils/stringUtils';
import {
	BatteryResultsTableData,
	isBatteryResultConcerning,
	isBatteryResultProcessing,
} from '../../schemas/table/batteryResultsSchema';

export const getBatteryResultsTableDataFilterOptions = (
	organizationId: string,
	participantId?: string | null | undefined
): SearchApiGetResearchAssignmentsRequest => {
	const filterFields: GetResearchAssignmentsFilterField[] = [];
	const filterOperators: GetResearchAssignmentsFilterOperator[] = [];
	const logicalOperators: GetResearchAssignmentsLogicalOperator[] = [];
	const filterValues: string[] = [];

	// Apply battery state filter (Default - we'll never get results that are not in the "complete" state in this case)
	const BATTERY_RESULT_STATES_TO_FILTER_ON = [
		BatteryResultState.AnalysisComplete,
		BatteryResultState.DataComplete,
		BatteryResultState.MetricsComplete,
		BatteryResultState.ParticipantComplete,
	];

	BATTERY_RESULT_STATES_TO_FILTER_ON.forEach((brs, index) => {
		filterFields.push(
			GetResearchAssignmentsFilterField.BatteryResultStatus
		);
		filterOperators.push(GetResearchAssignmentsFilterOperator.In);
		filterValues.push(brs);
		if (index === BATTERY_RESULT_STATES_TO_FILTER_ON.length - 1) {
			logicalOperators.push(GetResearchAssignmentsLogicalOperator.And);
		}
	});

	// Apply Participant ID filter (if any such list of IDs exists)
	if (isNonEmptyString(participantId)) {
		logicalOperators.push(GetResearchAssignmentsLogicalOperator.And);
		filterFields.push(GetResearchAssignmentsFilterField.ExternalId);
		filterOperators.push(GetResearchAssignmentsFilterOperator.In);
		filterValues.push(participantId);
	}

	return {
		organizationId,
		filterField: filterFields,
		logicalOperator: logicalOperators,
		filterOperator: filterOperators,
		filterValue: filterValues,
	};
};

export const mapBatteryResultToTableData = (
	orgBatteryResults?: PaginatedAssignmentsResearch
): BatteryResultsTableData[] => {
	const batteryResults = orgBatteryResults?.results ?? [];
	const data = batteryResults?.map((batteryResult) => {
		const participantId = batteryResult?.subject?.id ?? '';

		const { proctor } = batteryResult;
		const proctorName = truncateString(
			`${proctor?.lastName ?? 'Machine'}, ${
				proctor?.firstName ?? 'User'
			} `,
			20
		);

		const batteryResultTableData: BatteryResultsTableData = {
			id: batteryResult?.batteryResultId ?? '',
			participantId: participantId,
			externalId: batteryResult?.subject?.externalId,
			date: batteryResult?.endTime ?? '',
			batteryName: batteryResult.battery?.displayKey,
			notification: isBatteryResultConcerning(
				batteryResult.metrics,
				batteryResult.batteryResultState
			),

			administrator: proctorName,
			processing: isBatteryResultProcessing(
				batteryResult.batteryResultState
			),
			visitType: batteryResult?.visitType ?? '',
			delayed:
				batteryResult?.batteryResultState ===
				BatteryResultState.ProcessingError,
		};
		return batteryResultTableData;
	});
	return data ?? [];
};
