import { useContext, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { UserContext } from '../../../app/src/context/UserContext';
import { getZendeskSSOUrl } from 'api/organization';
import { QueryKey } from 'api/query';
import { ERROR, INFO } from 'logging/linusLogger';

export function useZendeskRedirect() {
	const { currentUser } = useContext(UserContext);
	const client = useQueryClient();

	useEffect(() => {
		async function zendeskSSOAuth(organizationId: string, userId: string) {
			const params = new URLSearchParams(window.location.search);
			const returnTo = params.get('return_to') ?? '';

			/**
			 * Only applies to zendesk users signing in via sso
			 */
			if (returnTo.match(/linushealth[\d]*.zendesk.com/gi)) {
				try {
					const zendeskRedirectUri = await client.fetchQuery({
						queryKey: [QueryKey.ZendeskUrl, organizationId, userId],
						queryFn: () =>
							getZendeskSSOUrl({
								organizationId,
								userId,
								returnTo,
							}),
					});
					// To be cleaned up in a follow-up PR
					INFO('Zendesk redirect uri', zendeskRedirectUri);
					window.location.replace(zendeskRedirectUri);
				} catch (err) {
					// Fail silently
					ERROR('error fetching zendesk sso url', err);
				}
			}
		}

		if (currentUser?.organizationId && currentUser?.id) {
			zendeskSSOAuth(currentUser.organizationId, currentUser.id);
		}
	}, [client, currentUser?.id, currentUser?.organizationId]);
}
