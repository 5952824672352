import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '../query';

import { getOrganizationService } from 'api/getOrganizationService';
import { isNonEmptyString } from '../../utils/stringUtils';
import { IOrganizationUser } from './types';

type FetchUserInput = {
	userId: string | null;
	organizationId: string | null;
};

export async function fetchUserById(
	input: FetchUserInput
): Promise<IOrganizationUser> {
	if (!input?.userId || !input.organizationId) {
		throw new Error(
			'fetchUserById: userId and/or organization IDs cannot be null'
		);
	}
	const service = await getOrganizationService();
	const { data } = await service.getUserById({
		userId: input.userId,
		organizationId: input.organizationId,
	});
	return data;
}

export function useGetUser(input: FetchUserInput) {
	return useQuery({
		enabled:
			isNonEmptyString(input.userId) &&
			isNonEmptyString(input.organizationId),
		meta: {
			errorMessage: `Error fetching data for user by ID: ${input?.userId}`,
		},
		queryKey: [QueryKey.User, input?.organizationId, input?.userId],
		queryFn: () => fetchUserById(input),
		staleTime: Infinity,
	});
}
