import {
	keepPreviousData,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';

import {
	PaginatedAssignmentsResearch,
	SearchApiGetResearchAssignmentsRequest,
} from 'api/battery';
import { getSearchService } from 'api/getSearchService';
import { CacheTimes, QueryKey } from 'api/query';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import {
	getBatteryResultsTableDataFilterOptions,
	mapBatteryResultToTableData,
} from './BatteryResults.helpers';
import { GetBatteryResultsTableDataInput } from './BatteryResults.types';

export async function fetchBatteryResultsByOrgId(
	requestOptions: SearchApiGetResearchAssignmentsRequest,
	signal: AbortSignal
): Promise<PaginatedAssignmentsResearch> {
	if (isEmpty(requestOptions?.organizationId)) {
		throw new Error("getResearchAssignments: organizationId can't be null");
	}
	const service = await getSearchService();
	const { data } = await service.getResearchAssignments(
		{
			...requestOptions,
		},
		{ signal }
	);
	return data;
}

const useGetBatteryResultsTableData = (
	input: GetBatteryResultsTableDataInput
) => {
	const client = useQueryClient();
	const [isRefreshing, setIsRefreshing] = useState(false);

	const query = useQuery({
		meta: {
			errorMessage: `Error fetching assignments for org ${input.organizationId}`,
		},
		staleTime: CacheTimes.FiveMinutes,
		placeholderData: keepPreviousData,
		queryKey: [
			QueryKey.BatteryResultsByOrg,
			input?.page,
			input?.pageSize,
			input?.organizationId,
			input?.searchValue,
			input?.sort?.sortField,
			input?.sort?.sortOrder,
		],
		queryFn: ({ signal }) =>
			fetchBatteryResultsByOrgId(
				{
					page: input?.page,
					pageSize: input?.pageSize,
					sortField: input?.sort?.sortField,
					sortOrder: input?.sort?.sortOrder,
					...getBatteryResultsTableDataFilterOptions(
						input.organizationId,
						input?.searchValue
					),
				},
				signal
			),
		select: (data) => {
			return {
				...data,
				data: mapBatteryResultToTableData(data),
			};
		},
	});

	async function refresh() {
		setIsRefreshing(true);
		await client.invalidateQueries({
			queryKey: [
				QueryKey.BatteryResultsByOrg,
				input?.page,
				input?.pageSize,
				input?.organizationId,
				input?.searchValue,
				input?.sort?.sortField,
				input?.sort?.sortOrder,
			],
		});
		setIsRefreshing(false);
	}

	return {
		...query,
		loading: query.isLoading,
		data: query.data?.data || [],
		totalCount: query.data?.totalCount || 0,
		isRefreshing,
		refresh,
	};
};

export { useGetBatteryResultsTableData };
