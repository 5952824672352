import { useQuery } from '@tanstack/react-query';
import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from '../query';
import { ZendeskArticleKeys } from '../../components/about/ZendeskArticle';

export interface GetZendeskUrlQueryInput {
	article?: ZendeskArticleKeys;
	locale?: string;
	organizationId: string;
	returnTo?: string;
	userId: string;
}

export async function getZendeskSSOUrl(
	input: GetZendeskUrlQueryInput
): Promise<string> {
	if (!input.organizationId) {
		throw new Error('getZendeskSSOUrl: organization ID cannot be null');
	}
	if (!input.userId) {
		throw new Error('getZendeskSSOUrl: user ID cannot be null');
	}
	const service = await getOrganizationService();
	const { data } = await service.getUserZendeskSSOUrl(input);
	return data;
}

export function useZendeskUrlQuery(input: GetZendeskUrlQueryInput) {
	// For debugging purposes - to be cleaned up in subsequent PR
	return useQuery({
		enabled: !!input.organizationId && !!input.userId,
		meta: {
			errorMessage: `Error fetching zendesk sso url for ${input.userId} in organization: ${input.organizationId}`,
		},
		queryKey: [QueryKey.ZendeskUrl, input.organizationId, input.userId],
		queryFn: () => getZendeskSSOUrl(input),
	});
}
