import * as yup from 'yup';
import { TParticipantDetails } from '../table/participantSchema';

export type removeBatteryModel = {
	issues: string[];
	otherIssues: string;
};

export type RemoveBatteryProps = {
	id: string;
	firstName?: string;
	lastName?: string;
	externalId?: string;
	organizationId?: string;
	assignmentInfo?: TParticipantDetails['assignmentInfo'];
	newPatient?: boolean;
};

export const getModel = (): removeBatteryModel => {
	return {
		issues: [],
		otherIssues: '',
	} as removeBatteryModel;
};

export const removeBatterySchema = yup.object().shape({
	issues: yup.array().of(yup.string()).nullable(),
	otherIssues: yup.string().notRequired(),
});
