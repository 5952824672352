import { get } from 'lodash';
import {
	OrganizationStatus,
	Organization,
	OrganizationType,
	UserStatus,
	User,
	UserOrganizationsRole,
	LinusPrincipal,
	OperationToken,
} from '@lh/core-backend-client';

import { ValueListItem, ValueListType } from 'generated/graphql';

import { CurrentUser } from 'types';
import { ValueListContainer } from 'store';
import { decodeOrganizationPermissions } from '@lh/eng-platform-rest-common/permissions/operationService';
import { OrganizationPermissionsBitmask } from '@lh/eng-platform-rest-common/authentication/dtos';
import { vlEnumeration } from 'enums/enumeration';
import { participantLanguageToPatientDisplayTransform } from 'utils/valueListUtils';
import { ValueListEntries } from '../context/UserContext';

export type GQLCurrentUserArgs = {
	user: User | null;
	principal: LinusPrincipal | null;
	role: UserOrganizationsRole | null;
	organization: Organization | null;
	valueLists: ValueListContainer | null;
};

export const buildGQLCurrentUserFromRest = ({
	user,
	principal,
	role,
	organization,
	valueLists,
}: GQLCurrentUserArgs): CurrentUser => {
	const mappedLangs = vlEnumeration(
		get(organization, 'preferences.participantLanguages', []),
		participantLanguageToPatientDisplayTransform
	);

	// Reduce the value lists down to a single obj and transform the value list items into EnumItems for backwards compat
	const mappedValueLists = Object.entries(valueLists || []).reduce(
		(prev, [currK, currV]) => {
			// These types from GQL and Rest are 1:1, but CI Typescript building hates it
			const newV = currV.map(
				(i) =>
					({
						__typename: 'ValueListItem',
						displayKey: i.displayKey,
						display: i.display,
						longDisplay: i.longDisplay,
						longDisplayKey: i.longDisplayKey,
						value: i.value,
						id: i.id,
					} as ValueListItem)
			);
			const newK = currK as ValueListType;

			return {
				...prev,
				[newK]: vlEnumeration(newV),
			};
		},
		{}
	);

	return {
		// User info
		id: get(user, 'id', ''),
		email: get(user, 'email', ''),
		firstName: get(user, 'firstName', ''),
		lastName: get(user, 'lastName', ''),
		phoneNumber: get(user, 'phoneNumber', ''),
		suffix: get(user, 'suffix', []),
		userStatus: get(user, 'status', UserStatus.Active),
		avatarUrl: get(user, 'avatarUrl', ''),

		// Role info
		role: get(role, 'roleName', ''),
		roles: get(user, 'roles', []),
		roleId: get(role, 'roleId', ''),
		operations: decodeCurrentOrgOperations(
			principal?.organizationPermissions || {},
			organization?.id || ''
		),

		// Org info
		organizationDateFormat: get(organization, 'dateFormat', ''),
		organizationDefaultTimezone: get(organization, 'defaultTimezone', ''),
		organizationFeatures: get(organization, 'preferences.features', []),
		organizationId: get(organization, 'id', ''),
		organizationName: get(organization, 'name', ''),
		organizationParticipantLanguages: mappedLangs,
		organizationStatus: get(
			organization,
			'status',
			OrganizationStatus.Test
		),
		organizationType: get(organization, 'type', OrganizationType.Research),
		organizationValueLists: mappedValueLists as ValueListEntries,
	};
};

export const decodeCurrentOrgOperations = (
	orgPermissions: { [key: string]: string },
	currentOrgId: string
) => {
	const operationsForRole = Object.entries(orgPermissions).find(
		([k]) => k === currentOrgId
	);

	if (operationsForRole) {
		const ofr: OrganizationPermissionsBitmask = {
			[operationsForRole[0]]: operationsForRole[1],
		};
		return decodeOrganizationPermissions(
			ofr,
			Object.values(OperationToken)
		)[0].operations as OperationToken[];
	}

	return [];
};
