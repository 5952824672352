import { DropdownOption } from '../../types/types';
import { Gender } from 'api/participant';
import i18n from '../../i18n';

/** The function returns the required order for genders list */
export function getGendersList() {
	const items: DropdownOption[] = [
		{
			display: i18n.t('web.enums.gender.male'),
			value: Gender.Male,
			longDisplay: undefined,
			secondLine: undefined,
		},
		{
			display: i18n.t('web.enums.gender.female'),
			value: Gender.Female,
			longDisplay: undefined,
			secondLine: undefined,
		},
		{
			display: i18n.t('web.enums.gender.transgenderMale'),
			value: Gender.TransgenderMale,
			longDisplay: undefined,
			secondLine: undefined,
		},
		{
			display: i18n.t('web.enums.gender.transgenderFemale'),
			value: Gender.TransgenderFemale,
			longDisplay: undefined,
			secondLine: undefined,
		},
		{
			display: i18n.t('web.enums.gender.nonBinary'),
			value: Gender.NonBinary,
			longDisplay: undefined,
			secondLine: undefined,
		},
		{
			display: i18n.t('web.enums.gender.other'),
			value: Gender.Other,
			longDisplay: undefined,
			secondLine: undefined,
		},
		{
			display: i18n.t('web.enums.gender.nonDisclosed'),
			value: Gender.NonDisclosed,
			longDisplay: undefined,
			secondLine: undefined,
		},
		{
			display: i18n.t('web.enums.gender.toBeCollectedAtTimeOfTesting'),
			value: Gender.ToBeCollectedAtTimeOfTesting,
			longDisplay: undefined,
			secondLine: undefined,
		},
	];

	return items;
}

export type ContactFields = {
	contactPreference?: ContactPreference;
	contactPhone?: string;
	contactEmail?: string;
};

export const BATTERY_TYPE = {
	IN_CLINIC: 'In Clinic',
	REMOTE: 'Remote',
} as const;

export type BatteryType = (typeof BATTERY_TYPE)[keyof typeof BATTERY_TYPE];

export const CONTACT_PREFERENCES = {
	EMAIL_ONLY: 'email_only',
	PHONE_ONLY: 'phone_only',
	EMAIL_AND_PHONE: 'email_and_phone',
} as const;

export type ContactPreference =
	(typeof CONTACT_PREFERENCES)[keyof typeof CONTACT_PREFERENCES];
export type ContactMethods =
	(typeof CONTACT_METHODS)[keyof typeof CONTACT_METHODS];

export const CONTACT_METHODS = {
	...CONTACT_PREFERENCES,
	NO_SEND: 'no_send',
} as const;

export function formatContactFields(fields: ContactFields) {
	switch (fields.contactPreference) {
		case CONTACT_PREFERENCES.EMAIL_AND_PHONE:
			return fields;
		case CONTACT_PREFERENCES.EMAIL_ONLY:
			return {
				...fields,
				contactPhone: fields.contactPhone ? fields.contactPhone : '',
			};
		case CONTACT_PREFERENCES.PHONE_ONLY:
			return {
				...fields,
				contactEmail: fields.contactEmail ? fields.contactEmail : '',
			};
		default:
			return fields;
	}
}
